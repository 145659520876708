<template>
    <v-card class="mx-auto">
      <v-btn plain class="ma-2" to="/admin/stats"><v-icon left>mdi-chart-bar-stacked</v-icon>estadisticas</v-btn>
          <v-col cols="12" md="4">
            <v-text-field
              v-mask="['X.XXX.XXX-X','XX.XXX.XXX-X']"
              v-model="rut"
              required
              prepend-inner-icon="mdi-card-account-details-outline"
              maxlength="12"
              placeholder="12.345.678-9"
              label="Ingrese su rut"
            ></v-text-field>
            <v-btn color="primary" @click="getName()">Verificar RUT</v-btn>
          </v-col>
          <v-col cols="12" md="8">
          <v-text-field
            v-model="nombre" placeholder="Nombre colaborador" readonly
            prepend-inner-icon="mdi-account-settings-outline"
          ></v-text-field>
          </v-col>
        <span v-if="msg">
        <p class="text--primary">
        El estado actual del colaborador es:
        <br>{{ingreso}} para el ingreso
        <br>
        <br>El nuevo estado del colaborador será:
        </p>
        <v-col cols="12" md="8">
          <v-select v-model="new_estado" placeholder="Seleccione una opción..." :items="respuesta"></v-select>
          </v-col>
        </span>
        <v-card-actions>
        <v-spacer></v-spacer>
         <v-text-field
            :disabled="!msg"
            v-model="val" label="Palabra clave"  type="password"
            prepend-inner-icon="mdi-lock-reset"
          ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn :disabled="!msg" @click="updateRut(),newConsulta()">Realizar cambio </v-btn>
        </v-card-actions>
        <v-dialog
        v-model="status_rut"
      >
      <v-alert 
      border="right"
      colored-border
      type="warning"
      elevation="2"
      >
      Error!<br><br>Rut ingresado inválido o no registrado en base de datos, por favor contáctese con su jefatura.
      </v-alert></v-dialog>
        <v-dialog v-model="check">
            <v-alert v-if="resp"
            border="right"
            colored-border
            type="success"
            elevation="2"
            >
            Cambio realizado exitosamente.
            </v-alert>
            <v-alert v-else
            border="right"
            colored-border
            type="error"
            elevation="2"
            >
            El cambio no se realizó.
            </v-alert>
        </v-dialog>
        </v-card>
        
</template>

<script>
import C19DataService from "../../services/C19DataService";
  export default {
    name: 'Home',

    data () {
      return {
        rut:"",
        nombre:"",
        new_estado: "",
        respuesta:[{text:"Habilitado para ingresar", value:false},{text:"No habilitado para ingresar",value:true}],
        ingreso:"",
        estado_c19:"",
        status_rut:false,
        msg:false,
        val:"",
        check:false,
        resp:"",
      }
    },

  methods:{
    getName(){
      var rut = this.rut.replace(/\./g, "").toUpperCase()
      C19DataService.getRut(rut)
      .then((response) => {
          this.estado_c19 = response.data[0].estado_c19
          this.nombre = response.data[0].nom_comp;
          this.estado_c19?this.ingreso="No habilitado":this.ingreso="Habilitado";
          this.msg=true
        })
        .catch((e) => {
          console.log(e);
          this.status_rut=true
        });

    },

    updateRut(){
      let data = {}
      data['val']=this.val
      data['rut']=this.rut.replace(/\./g, "").toUpperCase()
      data['estado_c19']=this.new_estado
      C19DataService.updateRut(data)
        .then((result) => {
            this.check=true
            this.resp=result.data

            })
    },

    newConsulta(){
        this.rut=""
        this.nombre=""
        this.estado_c19=""
        this.new_estado= ""
        this.ingreso=""
        this.estado_c19=""
        this.msg=false
        this.val=""
        this.check=false
        this.resp=""
    }
  },
  mounted(){
    // this.newConsulta()
    }
  }
</script>